document.addEventListener('turbolinks:load', () => {
  if(document.body.dataset.requiresMath!=='true'){
    return;
  }
  renderMathInElement(document.body, {
    delimiters: [
        {left: '$$', right: '$$', display: true},
        {left: '$', right: '$', display: false},
        {left: '\\(', right: '\\)', display: false},
        {left: '\\[', right: '\\]', display: true}
    ],
    throwOnError : false,
    trust: function(context){
      return ['\\htmlId', '\\href'].includes(context.command);
    },
    macros: {
      "\\eqref": "\\rm{eq.} \\href{###1}{(\\text{#1})}",
      "\\ref": "\\href{###1}{\\text{#1}}",
      "\\label": "\\htmlId{#1}{}"
    },
  });
  document.querySelectorAll(".katex-html a").forEach(function($link){
    $link.setAttribute("data-turbolinks", false);
  });
});
