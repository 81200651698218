import Alerts from '../utils/alerts';
import Dialogs from '../utils/dialogs';
import GoogleAnalytics from '../utils/google_analytics';

const init_form = function(selector) {
  const $form = document.querySelector(`${selector} form`),
    $alerts_container = document.querySelector(`${selector} .alerts_container`),
    clear_inputs = function(){
      $form.querySelectorAll("input").forEach(function($input){
        $input.value = "";
      });
    };

  if(!$form){
    return;
  }

  $form.addEventListener("ajax:beforeSend", () => {
    $alerts_container.innerHTML = "";
  });

  $form.addEventListener("ajax:success", (event) => {
    $alerts_container.append(Alerts.render_alert("Congratulations! You are on the list. We will let you know when we publish new content to the VectorLogic blog.", "success"));
    clear_inputs();
    GoogleAnalytics.track_event("subscribe", {
      blog_subscription_id: event.detail[0].blog_subscription_id
    });
  });

  $form.addEventListener("ajax:error", (event) => {
    const {info, errors} = event.detail[0];

    let $message = document.createElement("div");

    if(errors){
      $message.append(Alerts.render_alert(errors[0], "danger"));
    }else if(info){
      $message.append(Alerts.render_alert(info, "warning"));
    }else{
      $message.append(Alerts.render_generic_error());
    }

    $alerts_container.append($message);
  });
};

document.addEventListener('turbolinks:load', () => {
  Dialogs.init(); // Set up general dialog behaviours
  const form_selectors = ["#subscription_form", "#subscription_dialog_form"],
    $subscription_dialog = document.getElementById("subscription_dialog");

  // Set up form handling
  form_selectors.forEach(selector => init_form(selector));

  // Subscription form modal
  document.querySelectorAll("a[href='#subscription_form']").forEach(function($link){
    $link.addEventListener("click", function(e){
      e.preventDefault();
      $subscription_dialog.showModal();
    });
  });
});
