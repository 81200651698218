import './style.scss';
import './subscriptions';
import './previews';
import './katex';
import './quizzes';
import init_comments from '../comments';
import hljs from 'highlightjs';
import RecaptchaHelper from '../utils/recaptcha_helper';
import { add_code_copy_buttons } from './hljs_copy_buttons';
import { add_title_anchor_copy_links } from './title_anchor_copy_links';
import { init_chart_scroll_handlers } from './quiz_charts';
import { init_related_posts_add_button } from './related_posts';

document.addEventListener('turbolinks:load', () => {
  // Only run on edit
  if(document.querySelector("form.edit_blog_post, form.new_blog_post")){
    const $snippets = document.querySelectorAll("code.html, code.xml");
    for(const $snippet of $snippets){
      $snippet.innerHTML = $snippet.innerHTML
        .replaceAll('<', '&lt')
        .replaceAll('>', '&gt');
    }
  }
  // Only run on the individual post page
  if(document.querySelector(".post[data-title]")){
    init_comments();
    hljs.initHighlighting.called = false;
    hljs.initHighlighting();
    add_code_copy_buttons();
    add_title_anchor_copy_links();
    init_chart_scroll_handlers();
    init_related_posts_add_button();
  }
});
