const Alerts = {
  render_alert: function(msg, clazz){
    let $alert = document.createElement("div");
    $alert.classList.add("alert", "alert-dismissible", `alert-${clazz}`);
    $alert.setAttribute("role", "alert");
    $alert.append(document.createTextNode(msg));
    $alert.append(this.render_close_button());
    return $alert;
  },

  render_close_button: function(){
    let $close_btn = document.createElement("button");
    $close_btn.setAttribute("type", "button");
    $close_btn.classList.add("btn-close");
    $close_btn.setAttribute("data-bs-dismiss", "alert");
    $close_btn.setAttribute("aria-label", "Close");
    return $close_btn;
  },

  render_generic_error: function(){
    let msg = `An unexpected error has occurred.
      Please try again and if the problem persists please contact support at dev@vector-logic.com`;
    return render_alert(msg, "error");
  }
};

export default Alerts;
