import { Tooltip } from 'bootstrap';

export default function(){
  let add_list = document.querySelectorAll('.add_comment_btn');
  for(let i=0; i<add_list.length; i++){
    let add_btn = add_list[i];
    add_btn.addEventListener('click', function(event){
      event.preventDefault();
      event.target.closest('.comment_list_wrapper')
        .querySelector('.new_comment')
        .classList.remove('hidden');
    });
  }

  let edit_list = document.querySelectorAll('.edit_comment_link');
  for(let j=0; j<edit_list.length; j++){
    let edit_link = edit_list[j];
    edit_link.addEventListener('click', function(event){
      event.preventDefault();
      let comment_body = event.target.closest('.body');
      comment_body.querySelector('.show').classList.add('hidden');
      comment_body.querySelector('.edit').classList.remove('hidden');
    });
  }

  let cancel_list = document.querySelectorAll('.cancel_edit_comment_btn');
  for(let k=0; k<cancel_list.length; k++){
    let cancel_btn = cancel_list[k];
    cancel_btn.addEventListener('click', function(event){
      event.preventDefault();
      let comment_body = event.target.closest('.body');
      comment_body.querySelector('.show').classList.remove('hidden');
      comment_body.querySelector('.edit').classList.add('hidden');
    });
  }

  let reply_list = document.querySelectorAll('.reply_comment_link');
  for(let j=0; j<reply_list.length; j++){
    let reply_link = reply_list[j];
    reply_link.addEventListener('click', function(event){
      event.preventDefault();
      let comment_body = event.target.closest('.body');
      comment_body.querySelector('.reply').classList.remove('hidden');
      comment_body.querySelector('.reply_cancel_link').classList.remove('hidden');
      reply_link.classList.add('hidden');
    });
  }

  let reply_cancel_list = document.querySelectorAll('.reply_cancel_link');
  for(let j=0; j<reply_cancel_list.length; j++){
    let reply_cancel_link = reply_cancel_list[j];
    reply_cancel_link.addEventListener('click', function(event){
      event.preventDefault();
      let comment_body = event.target.closest('.body');
      comment_body.querySelector('.reply').classList.add('hidden');
      comment_body.querySelector('.reply_comment_link').classList.remove('hidden');
      reply_cancel_link.classList.add('hidden');
    });
  }

  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function($el){
    new Tooltip($el);
  });
}
