document.addEventListener('turbolinks:load', () => {
  const url = new URL(window.location.href),
    attempt_url = url.searchParams.get("attempt_url"),
    $iframe = document.querySelector("iframe[name=quiz]");

  if(!attempt_url){
    return;
  }
  if($iframe.src !== attempt_url){
    console.log("Reloading");
    $iframe.src = attempt_url;
  }
});
