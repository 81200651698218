import './dialog_styles.scss';

const init_dialog = function($dialog){
  const $close_buttons = $dialog.querySelectorAll(".close");
  $close_buttons.forEach(function($close_button){
    $close_button.addEventListener("click", function(e){
      e.preventDefault();
      $dialog.close();
    });
  });
};

const Dialogs = {
  init: function(){
    const $dialogs = document.querySelectorAll("dialog");

    // Set up general dialog behaviours
    $dialogs.forEach($dialog => init_dialog($dialog));
  }
};

export default Dialogs;
