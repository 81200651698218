document.addEventListener('turbolinks:load', () => {
  const $previews = document.querySelector("#existing_previews");
  if(!$previews){
    return;
  }

  const $delete_links = $previews.querySelectorAll(".delete_preview"),
    $create_link = $previews.querySelector(".create_preview"),
    $modal_body = $previews.querySelector(".modal-body");

  $delete_links.forEach(function($delete_link){
    const $preview = $delete_link.closest(".preview");
    $delete_link.addEventListener("ajax:success", function(e){
      $modal_body.removeChild($preview);
    });
  });
  $create_link.addEventListener("ajax:success", function(e){
    const [data, status, xhr] = event.detail;
    $modal_body.innerHTML = $modal_body.innerHTML + event.detail[2].response;
  });
});
