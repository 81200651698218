document.addEventListener("turbolinks:request-start", function(event) {
  var xhr = event.data.xhr,
    csp_nonce = document.querySelector("meta[name='csp-nonce']").getAttribute("content");
  xhr.setRequestHeader("X-Turbolinks-Nonce", csp_nonce);
});
document.addEventListener("turbolinks:before-cache", function() {
  document.querySelectorAll('script[nonce]').forEach(function($element) {
    $element.setAttribute('nonce', $element.getAttribute('nonce'));
  });
});
