const RecaptchaHelper = {
  init: function(){
    let fired = false;
    window.addEventListener('scroll', function () {
      let scroll = window.scrollY;
      if (scroll > 0 && fired === false) {
        var recaptchaScript = document.createElement('script');
        recaptchaScript.src = "https://www.google.com/recaptcha/api.js";
        recaptchaScript.defer = true;
        document.body.appendChild(recaptchaScript);
        fired = true;
      }
    }, true);
  },

  on_success: function(response){
    document.querySelectorAll(".g-recaptcha").forEach(function($recaptcha){
      const $form = $recaptcha.closest("form");
      // Enable submit button
      $form.querySelectorAll(".submit_btn,.btn-success").forEach(function($btn){
        $btn.removeAttribute("disabled");
      });
    });
  },
};

RecaptchaHelper.init();
export default RecaptchaHelper;
