const related_post_form_template = function(i){
  return `
  <tr class="related_post new">
    <td class="related_post_id">
      <input type="text" name="blog_post[related_posts_attributes][${i}][related_blog_post_id]" id="blog_post_related_posts_attributes_${i}_related_blog_post_id">
    </td>
    <td class="title">
      <input type="text" name="blog_post[related_posts_attributes][${i}][title]" id="blog_post_related_posts_attributes_${i}_title">
    </td>
    <td class="position">
      <input type="text" name="blog_post[related_posts_attributes][${i}][position]" id="blog_post_related_posts_attributes_${i}_position">
    </td>
    <td class="destroy">
      <input name="blog_post[related_posts_attributes][${i}][_destroy]" type="hidden" value="0" autocomplete="off"><input type="checkbox" value="1" name="blog_post[related_posts_attributes][${i}][_destroy]" id="blog_post_related_posts_attributes_${i}__destroy">
    </td>
  </tr>`;
}

export const init_related_posts_add_button = function(){
  const $btn = document.getElementById("add_related");
  if(!$btn){
    return;
  }
  $btn.addEventListener("click", function(e){
    const $table = $btn.closest("table.related_posts"),
      index = $table.querySelectorAll("tr.related_post").length;

    $table.querySelector("tbody")
      .insertAdjacentHTML('beforeend', related_post_form_template(index));
  });
}
