import './click_to_copy_styles.scss';

const build_link = function(id){
  const loc = window.location;
  return [
    loc.protocol,
    "//",
    loc.host,
    loc.pathname,
    `#${id}`
  ].join('');
};
const add_title_anchor_copy_links = function(){
  if(!navigator.clipboard){
    return;
  }
  document.querySelectorAll(".post h2:not(.copy-disabled),.post h3:not(.copy-disabled)").forEach(function($title){
    if(!$title.id){
      return;
    }
    const content = build_link($title.id),
      $btn_wrapper = document.createElement("div"),
      $copy_btn = document.createElement("button");

    $copy_btn.title = "Copy to clipboard";
    $btn_wrapper.classList.add("click-to-copy");
    $btn_wrapper.appendChild($copy_btn);
    $title.appendChild($btn_wrapper);
    $copy_btn.addEventListener("click", function(){
      try {
        navigator.clipboard.writeText(content);
        $copy_btn.classList.add("copied");
        $copy_btn.title = "Copied";
        setTimeout(function () {
          $copy_btn.classList.remove("copied");
          $copy_btn.title = "Copy to clipboard";
        }, 1200)
      } catch (err) {
        $copy_btn.classList.add("copy-failed");
        console.error('Failed to copy!', err);
      }
    });
  });
};

export { add_title_anchor_copy_links };
