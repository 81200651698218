import { Modal } from 'bootstrap';

let chart_js_loaded = false,
  charts_initialized = false;


const load_chart_js = function(){
  const s = document.createElement('script')
  s.src = 'https://cdn.jsdelivr.net/npm/chart.js';
  s.type = 'text/javascript'
  s.async = !0
  s.onload = function(){
    chart_js_loaded = true;
    init_quiz_charts();
  }
  document.head.appendChild(s);
};

const init_quiz_charts = function(){
  if(charts_initialized){
    return;
  }
  document.querySelectorAll(".quiz-chart").forEach(function($chart){
    init_chart($chart.getAttribute("id"));
  });
  charts_initialized = true;
};

const init_chart_scroll_handlers = function(){
  if(!document.querySelector(".quiz-chart")){
    return;
  }
  const observer = new IntersectionObserver(function(entries, observer){
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        load_chart_js();
      }
    });
  }, {
    rootMargin: "50px",
    threshold: 0.2
  });
  observer.observe(document.querySelector(".quiz-chart"));
};

const build_modal_for_question = function(number="", img_url=""){
  const id = `vl-modal-qu-${number}`;
  let $modal = document.getElementById(id);
  if(!$modal){
    const html_str =
      `<div id="${id}" class="modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">Question ${number}</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <img src=${img_url} alt="Screenshot of question ${number}" />
            </div>
          </div>
        </div>
      </div>`;
    document.body.insertAdjacentHTML('afterbegin', html_str);
    $modal = document.getElementById(id);
  }
  return $modal;
};

const init_chart = function(id){
  const $data_el = document.getElementById(id + "-data"),
    data_txt = $data_el.innerHTML,
    chart_data = JSON.parse(data_txt.replace(/\n/g, "")),
    url_pattern = $data_el.dataset.urlPattern,
    quiz_title = $data_el.dataset.quizTitle,
    ctx = document.getElementById(id);
  const handle_click = function(event){
    const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const question_number = chart.data.labels[points[0].index].split(" ")[1],
        question_url = url_pattern.replace("<QUESTION_NUMBER>", question_number),
        $modal_elem = build_modal_for_question(question_number, question_url),
        question_modal = new Modal($modal_elem);
      question_modal.show();
    }
  };
  const chart = new Chart(ctx, Object.assign(chart_data, {
      options: {
        onClick: handle_click,
        plugins: {
          title: {
            display: true,
            text: quiz_title,
            padding: 10,
            font: {
              size: 18
            }
          },
          tooltip: {
            enabled: true,
            position: 'nearest'
          }
        }
    }}));
};

export { init_chart_scroll_handlers };
