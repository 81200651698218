import './click_to_copy_styles.scss';

const add_code_copy_buttons = function(selector){
  if (!navigator.clipboard) {
    return;
  }
  document.querySelectorAll("pre code:not(.copy-disabled)").forEach(function($snippet){
    const $pre = $snippet.parentNode,
      code = $snippet.innerText,
      $btn_wrapper = document.createElement("div"),
      $copy_btn = document.createElement("button");

    $copy_btn.title = "Copy to clipboard"
    $btn_wrapper.classList.add("click-to-copy");
    $btn_wrapper.appendChild($copy_btn);
    $pre.appendChild($btn_wrapper);
    $copy_btn.addEventListener("click", function(){
      try {
        navigator.clipboard.writeText(code);
        $copy_btn.classList.add("copied");
        $copy_btn.title = "Copied"
        setTimeout(function () {
          $copy_btn.classList.remove("copied");
          $copy_btn.title = "Copy to clipboard"
        }, 1000)
      } catch (err) {
        $copy_btn.classList.add("copy-failed");
        console.error('Failed to copy!', err);
      }
    });
  });
};

export { add_code_copy_buttons };
