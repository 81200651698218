const GoogleAnalytics = {
  init: function(){
    document.addEventListener("turbolinks:load", function(event){
      if(typeof gtag === "function"){
        gtag('config', 'UA-58463126-4', {
          'page_path' : event.target.location.pathname,
          'page_title': event.target.title
        });
      }
    });
  },

  track_event: function(event_name, event_data = {}) {
    if(typeof gtag === "function"){
      gtag("event", event_name, event_data);
    }
  }
};

GoogleAnalytics.init();

export default GoogleAnalytics;
